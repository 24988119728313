import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/heroes/hero"
import HeroData from "../content/home/hero.json"
import QuickWinsData from "../content/home/highlights.json"
import CtaData from "../content/home/cta.json"
import QuickWinsHighlights from "../components/misc/highlights"
import { Container } from "react-bootstrap"
import SimpleCTA from "../components/ctas/simple-cta"

const IndexPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={intl.formatMessage({ id: "pages.home.title" })}
             description={intl.formatMessage({ id: "pages.home.description" })}
        />
        <Hero
            theme={`dark`}
            content={HeroData[intl.locale]}
        />
        <QuickWinsHighlights content={QuickWinsData[intl.locale]} />
        <Container>
          <div className={`mb-5`}>
            <SimpleCTA theme={`secondary`} content={CtaData[intl.locale]} />
          </div>
        </Container>
    </Layout>
)

export default injectIntl(IndexPage)
