import React from 'react';
import { Container, Row, Col, Card } from "react-bootstrap"
import {injectIntl} from "gatsby-plugin-react-intl"
import logo from "../../images/notificare-actito-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class QuickWinsHighlights extends React.Component {

    render() {
        return (

            <div className={`quickwins-highlights`}>
                <Container>
                    <div className={`title`}>{this.props.content.title}</div>
                    <Row>
                        {this.props.content.videos.map((video, i) => {
                            return (
                                <Col key={i} lg={4}>
                                  <div className={video.alignment ? `simple-card ` + video.alignment : `simple-card`}>
                                    <Card>
                                      <a className={`card-button`} href={video.button.url} target={video.button.target} rel={`noopener`}>

                                        <div className={`card-image`}>
                                          <img alt={``} src={logo} className={`logo`} />
                                        </div>

                                        <Card.Body>
                                          {video.title &&
                                          <Card.Title>{video.title}</Card.Title>
                                          }
                                          {video.subtitle &&
                                          <Card.Subtitle className="mb-2 text-muted">{video.subtitle}</Card.Subtitle>
                                          }
                                          {video.text &&
                                          <Card.Text dangerouslySetInnerHTML={{__html: video.text}}></Card.Text>
                                          }
                                          {video.button &&
                                          <div className={`button`}>{video.button.label} <FontAwesomeIcon icon="arrow-right" /></div>
                                          }
                                        </Card.Body>
                                      </a>
                                    </Card>
                                  </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(QuickWinsHighlights)
