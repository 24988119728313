import React from "react"
import { Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import Search from "../misc/search"

const Hero = ({ intl, theme, content }) => (
    <div className={`hero ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <h1>{content.title}</h1>
                <p className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></p>
                <Search />
            </Container>
        </div>
    </div>
)

export default injectIntl(Hero)
