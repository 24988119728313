import React from 'react'
import { Container, Form, FormControl, Button } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: "",
            hasQueryError: false,
            hasNoResults: false,
            results: []
        }

    }

    getSearchResults(query) {

        if (!query || !window.__LUNR__) {
            return [];
        }

        const lunrIndex =  window.__LUNR__[this.props.intl.locale];
        const results = lunrIndex.index.search(query); // you can  customize your search , see https://lunrjs.com/guides/searching.html
        return results.map(({ ref }) => lunrIndex.store[ref]);

    }

    handleInputChange = event => {

        const target = event.target
        const value = target.value

        this.setState({
            hasQueryError: false,
            hasNoResults: false,
            query: value,
            results: []
        });
    }

    handleSearch = event => {
        event.preventDefault();

        if (!this.state.query) {

            this.setState({
                hasQueryError: true
            });

            this.clearErrors();

        } else {

            const results = this.getSearchResults(this.state.query);

            if (results && results.length > 0) {

                this.setState({
                    results: results
                });

            } else {

                this.setState({
                    hasNoResults: true,
                    results: []
                });

                this.clearErrors();

            }

        }
    }

    clearErrors = () => {
        setTimeout(() => {
            this.setState({
                hasQueryError: false,
                hasNoResults: false
            });
        }, 8000);
    }

    render() {
        return (
            <div className={`search`}>
                <Container>
                    <div className={`form`}>
                      <Form className={`form-area`} onSubmit={this.handleSearch}>
                          <FormControl type="text" value={this.state.query} placeholder={this.props.intl.formatMessage({ id: "components.search.query.placeholder" })} className="form-field" onChange={this.handleInputChange} />
                          <Button className={`button`} variant="dark" type="submit"><FormattedMessage id="components.search.query.button" /></Button>
                      </Form>
                      {this.state.hasQueryError === true && <div className={`form-feedback`}><FormattedMessage id="components.search.query.error" /></div>}
                      {this.state.hasNoResults === true && <div className={`form-feedback`}><FormattedMessage id="components.search.query.noResults" /></div>}
                    </div>
                    {this.state.results.length > 0 &&
                    <div className={`results open`}>
                        <div className={`scrollable-area`}>
                            {this.state.results.map((page, i) => {
                                return (
                                  <a key={i} href={page.url} className={`shortcut`}>
                                      <div className={`title`}>{page.title}</div>
                                      <div className={`url`}>{page.url}</div>
                                  </a>
                                )
                            })}
                        </div>
                    </div>
                    }
                </Container>
            </div>
        )
    }
}

export default injectIntl(Search)
